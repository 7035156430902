import { Button } from '@/components/Button';
import { KargoBanner } from '@/components/KargoBanner/KargoBanner';
import Layout from '@/components/Layout';
import TrustedBy from '@/components/TrustedBy';
import { useBreakpoints } from '@/hooks/useBreakpoints';
import Checkmarkcon from '@/static/assets/icons/AkuityEnterprise/icn-checkmark.svg';
import DialBackIcon from '@/static/assets/icons/AkuityEnterprise/icn-dial-back.svg';
import BellIcon from '@/static/assets/icons/AkuityEnterprise/icn-service-bell.svg';
import UmbrellaIcon from '@/static/assets/icons/AkuityEnterprise/icn-umbrella.svg';
import EyeControlIcon from '@/static/assets/icons/AkuityPlatform/new/icn-eye-control.svg';
import SafeIcon from '@/static/assets/icons/AkuityPlatform/new/icn-safe.svg';
import ScaleIcon from '@/static/assets/icons/AkuityPlatform/new/icn-scale.svg';
import FaceIcon from '@/static/assets/icons/HomePage/Others/icn-face.svg';
import { seoConfiguration } from '@/utils/seo-config';
import { AKUITY_CLOUD_PAGE_URL } from '@/utils/urlUi';
import './argo-enterprise.scss';

const ArgoEnterprisePage = () => {
  const {
    title: titleSeo,
    image,
    description
  } = seoConfiguration.akuityEnterprise;
  const { isBreakpointRange } = useBreakpoints();

  const isTablet = isBreakpointRange('tablet');

  return (
    <>
      <Layout image={image} title={titleSeo} description={description}>
        <div className="argo-enterprise">
          <div className="header">
            <h1>Akuity Enterprise for Argo</h1>
            <h3 className="red">
              On-prem managed service for Argo, directly from its creators
            </h3>
            <h3>
              Everything needed to scale Argo with confidence. Enterprise
              support, solution guidance, architectural review for Argo CD, Argo
              Workflows, Argo Rollouts, and Argo Events.
            </h3>

            <Button
              additionalClass="cta secondary-grey button-hover-color--secondary"
              color="akuity-purple"
              link="/talk-to-sales"
            >
              Talk to Sales
            </Button>
          </div>

          <div className="features">
            <div className="features__item">
              <div className="features__icon">
                <UmbrellaIcon />
              </div>
              <h5>Minimized overhead</h5>
              <p>
                Automated updates, security patches and architectural review.
                Implement best practices for Argo in production and fine tune
                your implementation.
              </p>
            </div>
            <div className="features__item">
              <div className="features__icon">
                <BellIcon />
              </div>
              <h5>24/7 Enterprise support</h5>
              <p>
                Get help directly from Argo project creators. With an SLA in
                place you know what you can expect and how to reach us, no
                matter the time of day.
              </p>
            </div>
            <div className="features__item">
              <div className="features__icon">
                <EyeControlIcon />
              </div>
              <h5>Single control plane</h5>
              <p>
                Aggregate and query information from all your Argo CD apps in a
                single dashboard. DORA metrics and audit logs included.
              </p>
            </div>
            <div className="features__item">
              <div className="features__icon">
                <Checkmarkcon />
              </div>
              <h5>CVE resolution</h5>
              <p>
                Common vulnerabilities and exposures patched on demand in a
                timely manner.
              </p>
            </div>
            <div className="features__item">
              <div className="features__icon">
                <FaceIcon />
              </div>
              <h5>GitOps best practices</h5>
              <p>
                Detect configuration drift and roll-back to avoid disasters.
                Keep your environments synchronised and deploy to Kubernetes at
                scale.
              </p>
            </div>
            <div className="features__item">
              <div className="features__icon">
                <DialBackIcon />
              </div>
              <h5>Backup, Restore, Recover</h5>
              <p>
                Specify a storage space which we will periodically snapshot and
                restore your production configuration with just one click.
              </p>
            </div>
          </div>

          <Button
            additionalClass="cta secondary-grey button-hover-color--secondary"
            color="akuity-purple"
            link="/talk-to-sales"
          >
            Talk to Sales
          </Button>

          <div className="argo-enterprise__trustedBy">
            <TrustedBy />
          </div>

          <div className="highlight-wrapper">
            <h2>Interested in Cloud Solution for Argo?</h2>
            <h3>
              Fast setup, better security, less operational headaches, and much
              more
            </h3>

            <div className="features">
              <div className="features__item">
                <div className="features__icon">
                  <SafeIcon />
                </div>
                <h5>Next-level Security</h5>
                <ul>
                  <li>
                    Secrets are isolated within the cluster, limiting
                    vulnerability to 3rd party intrusions
                  </li>
                  <li>
                    No need to have Kubernetes credentials in a centralized
                    location
                  </li>
                  <li>
                    Networks remain private, with only outbound traffic required
                  </li>
                </ul>
              </div>
              <div className="features__item">
                <div className="features__icon">
                  <EyeControlIcon />
                </div>
                <h5>Single Control Plane</h5>
                <ul>
                  <li>
                    Manage all your applications and environments using one
                    control plane
                  </li>
                  <li>
                    One click version updates across all apps and environments
                  </li>
                </ul>
              </div>
              <div className="features__item">
                <div className="features__icon">
                  <ScaleIcon />
                </div>
                <h5>Scale up Instantly</h5>
                <ul>
                  <li>No need for manual sharding</li>
                  <li>No need for fine-tuning Argo CD components</li>
                  <li>Better resource allocation</li>
                  <li>
                    No network bottlenecks, we only transmit deployment metadata
                  </li>
                </ul>
              </div>
            </div>
            <Button
              additionalClass="cta secondary-grey button-hover-color--secondary"
              color="akuity-purple"
              link={AKUITY_CLOUD_PAGE_URL}
            >
              {isTablet ? 'Akuity Platform' : 'Check out the Akuity Platform'}
            </Button>
          </div>
          <KargoBanner />
        </div>
      </Layout>
    </>
  );
};

export default ArgoEnterprisePage;
