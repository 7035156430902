import './TrustedBy.scss';
import { commonComponentsContent } from '@/utils/contentData/commonComponents';

const { title, listOfCompanies } = commonComponentsContent.trustedBy;

const TrustedBy = () => {
  const getPartners = () =>
    listOfCompanies.map(({ logo: Logo }, index) => {
      return (
        <div key={index} className={'trusted-by__logo'}>
          <Logo />
        </div>
      );
    });

  return (
    <div className={'trusted-by'}>
      <div className="trusted-by__title">{title}</div>
      <div className="trusted-by__logos">{getPartners()}</div>
    </div>
  );
};

export default TrustedBy;
